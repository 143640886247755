<template>
  <div class="full-page login-page">
    <div class="content">
      <div class="container">
        <b-row>
          <b-col>
            <transition-page>
              <b-card v-if="state=='login'" id="username-form" title="Login">
                <form @submit.prevent="checkOrg()">
                  <lar-cognito-username-input label="Username" @usernameInput="e => username = e.detail" />
                  <lar-cognito-button color="primary" type="submit">
                    Continue
                  </lar-cognito-button>
                </form>
              </b-card>
            </transition-page>

            <transition-page>
              <b-card v-if="state=='org'" title="Login">
                <b-form-group v-if="orgs.length > 1" label="Organization">
                  <b-form-select v-model="org" :options="orgs" />
                </b-form-group>
                <div class="login-component">
                  <lar-cognito-login
                    :username="username"
                    disable-username="true"
                    show-back="true"
                    cognito-username-attribute="username"
                    @loading="loader"
                    @back="state = 'login'"
                    @loginDone="loginDone"
                    @loginError="e => error('Error', e.detail)"
                  />
                </div>
              </b-card>
            </transition-page>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import TransitionPage from '../components/pransition-page.vue';
import Amplify from '../services/Amplify';

export default {
  components: {
    TransitionPage,
  },
  data () {
    return {
      state: 'login',
      username: '',
      orgs: [],
      org: undefined,
      loading: undefined,
    };
  },
  watch: {
    org: async function () { // this binded
      await Amplify.setOrg(this.org).configure();
    }
  },
  methods: {
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    loader (e) {
      if (e.detail === true) {
        this.loading =this.$loading.show();
      } else if (this.loading) {
        this.loading.hide();
        this.loading = undefined;
      }
    },
    loginDone () {
      this.$store.dispatch('FETCH_ME');
      this.$router.push('/');
    },
    checkOrg (){
      return this.$http.post('orgs/loginparam', { username: this.username })
        .then(({ data }) => {
          if (!data || data.length === 0) {
            throw new Error('User not found');
          }
          // select first org
          this.org = data[0];
          this.orgs = data.map(org => {
            return {
              value: org,
              text: org.alias
            };
          });
        })
        .then(() => {
          this.state = 'org';
        })
        .catch((err) => {
          this.error('Error', err.message);
        });
    },
  },
};
</script>

<style lang="scss">
#username-form select {
  appearance: auto !important;
}
</style>
<style scoped lang="scss">
.login-page > .content {
  padding-top: 10vh;
}

.login-page .card {
  position: absolute;
  width: 400px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.login-page {
  min-height: calc(100vh - 4rem);
  height: 100%;
  --lar-text-color: #000;
}
.form-group {
  margin-bottom: 0;
}
</style>
